/**
 * Middleware для проверки авторизации пользователя
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { $pinia } = useNuxtApp();
  const userStore = useUserStore($pinia);
  if (!userStore.isLoggedIn) {
    await userStore.fetchUser();
  }

  // если это публичная страница
  if (to.meta.isPublic) return;

  // если пользователь залогинен
  if (userStore.isLoggedIn) return;

  // иначе кидаем на стартовый экран
  await navigateTo('/', { external: true });
});
